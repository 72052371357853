@import './gumstack_variables';
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-40 {
  font-size: 40px;
}

.fs-sm-12 {
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.fs-sm-14 {
  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}

.fs-sm-16 {
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.fs-sm-18 {
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}

.fs-sm-20 {
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

.fs-sm-24 {
  @include media-breakpoint-down(sm) {
    font-size: 24px;
  }
}

.lh-20 {
  line-height: 20px;
}

.ls-1 {
  letter-spacing: 1px;
}

.text-dark {
  color: #000 !important;
}

.text-dull {
  color: #666666;
}

.text-orange {
  color: #ffa339;
}

.text-light-secondary {
  color: #e0e0e0;
}

.text-dull-black {
  color: #3a3a3a;
}

.text-dull-secondary {
  color: #a3a3a3;
}

.text-light {
  color: #999 !important;
}

.text-new-primary {
  color: map-get($gs-primary-pallet, 500);
}

.text-primary-300 {
  color: map-get($gs-primary-pallet, 300);
}

.text-black {
  color: #000;
}

.text-brown {
  color: #82692b;
}

.text-primary-600 {
  color: map-get($gs-primary-pallet, 600);
}

.text-warning-500 {
  color: map-get($gs-warning-pallet, 500);
}

.text-warning-400 {
  color: map-get($gs-warning-pallet, 400);
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}