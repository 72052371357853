@import './gumstack_variables';

.cursor-pointer {
  cursor: pointer;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-primary-dull {
  color: map-get($gs-primary-pallet, 400);

  &:hover {
    color: map-get($gs-primary-pallet, 400);
  }
}

.text-warning-300 {
  color: map-get($gs-warning-pallet, 300);
}

.text-warning-500 {
  color: map-get($gs-warning-pallet, 500);
}

.btn-primary-dull {
  background-color: map-get($gs-primary-pallet, 400);
  color: white;

  &:hover {
    color: white;
  }
}

.btn-primary.transparent {
  background-color: #f4f5fa !important;
  color: map-get($gs-primary-pallet, 500);
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.nav-tabs-primary-dull {
  .nav-link.active {
    color: map-get($gs-primary-pallet, 400) !important;
    border-bottom: 4px solid map-get($gs-primary-pallet, 400) !important;
  }

  .nav-link:hover {
    border-bottom: none;
  }
}

.mt-40 {
  margin-top: 40px !important;

  @media screen and (max-height: 512px) {
    margin-top: 30px !important;
  }
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-80 {
  padding-bottom: 100px;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-16 {
  border-radius: 16px;
}

.rounded-30 {
  border-radius: 30px;
}

.border-dull {
  border: 1.145px solid #e0e0e0 !important;
}

.border-dashed-top {
  border-top: 1px dashed #e0e0e0;
}

.border-solid-top {
  border-top: 1px solid #e0e0e0;
}

.border-bottom-dull {
  border-bottom: 1px solid #f6f6f6;
}

.border-bottom-neutral-100 {
  border-bottom: 1px solid map-get($gs-neutral-pallet, 100);
}

.bg-warning-light {
  background: #fef2e5;
}

.bg-success-100 {
  background: map-get($gs-success-pallet, 100) !important;
}

.bg-primary-100 {
  background: map-get($gs-primary-pallet, 100) !important;
}

.bg-dull {
  background-color: #f6f6f6;
}

.bg-light-secondary {
  background-color: #fafafa !important;
}

.bg-dull-light {
  background-color: rgba(250, 250, 250, 0.5);
}

.bg-black-transparent {
  background: rgba(0, 0, 0, 0.5);
}

.bg-warning-300 {
  background: map-get($gs-warning-pallet, 300);
}

.bg-warning-400 {
  background: map-get($gs-warning-pallet, 400);
}

.bg-primary-200 {
  background-color: map-get($gs-primary-pallet, 200);
}

.bg-primary-400 {
  background-color: map-get($gs-primary-pallet, 400);
}

.bg-primary-800 {
  background-color: #f9f9f9;
}

.z-index-100 {
  z-index: 100;
}

.form-control-gray {
  background-color: #fafafa !important;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.w-sm-50 {
  @include media-breakpoint-down(sm) {
    width: 50%;
  }
}

.h-sm-auto {
  @include media-breakpoint-down(sm) {
    height: auto !important;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-1 {
  flex: 1;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.btlr-25 {
  border-top-left-radius: 25px;
}

.btrr-25 {
  border-top-right-radius: 25px;
}

.bbrr-25 {
  border-bottom-right-radius: 25px;
}

.btrr-0 {
  border-top-right-radius: 0;
}

.bbrr-0 {
  border-bottom-right-radius: 0;
}

.btlr-0 {
  border-top-left-radius: 0;
}

.bblr-0 {
  border-bottom-left-radius: 0;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.shadow-md {
  @include media-breakpoint-up(md) {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }
}

.divider {
  border-top: 4px solid #f6f6f6;
}

.scrollbar-primary {
  &::-webkit-scrollbar-track {
    @extend .bg-white;
  }

  &::-webkit-scrollbar-thumb {
    @extend .bg-primary-200;
  }

  &::-webkit-scrollbar-thumb:hover {
    @extend .bg-primary-400;
  }
}

.scrollbar-v-thin {
  &::-webkit-scrollbar {
    width: 6px;
  }

  @extend .scrollbar-primary;
}

.scrollbar-h-thin {
  &::-webkit-scrollbar {
    height: 4px;
  }

  @extend .scrollbar-primary;
}

.list-style-type-none {
  list-style-type: none;
}
