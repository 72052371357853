@import 'bootstrap';
@import './font_helpers';
@import './helper';
$flagsImagePath: '~intl-tel-input/build/img/';
@import '~intl-tel-input/src/css/intlTelInput';

body {
  background-color: rgb(240, 253, 244);
}

.ff-pacifo {
  font-family: 'Pacifico', cursive;
}

label.is-invalid {
  color: map-get($gs-error-pallet, 500) !important;
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.ml-lg-1 {
  position: absolute;
  top: 25%;
  left: -125%;
}

$primary-color: #4ed97f;
$primary-color-hover: #27c05d;
$secondary-color: #d5dee9;
$font-color: #262d34;
$font-color-inverse: #ffffff;
$border-radius: 4px;

@mixin button-styles($background-color, $border-color, $font-color) {
  background: $background-color;
  border: 1px solid $border-color;
  color: $font-color;
  border-radius: $border-radius;
  font-weight: 500;
}

.bg-primary {
  background-color: #4ed97f !important;
}

.btn-primary {
  @include button-styles($primary-color, $primary-color-hover, $font-color);

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    @include button-styles(
      $primary-color-hover,
      $primary-color-hover,
      $font-color
    );
  }

  &:disabled {
    @include button-styles($primary-color, $primary-color, $font-color-inverse);
  }
}

.btn-outline-primary {
  @include button-styles($font-color-inverse, $primary-color, $font-color);

  &:hover,
  &:focus,
  &:active {
    @include button-styles($primary-color, $primary-color, $font-color);
  }
}

.btn-secondary {
  @include button-styles(
    $secondary-color,
    $secondary-color,
    $font-color-inverse
  );

  &:hover,
  &:focus,
  &:active {
    @include button-styles(
      $secondary-color,
      $secondary-color,
      $font-color-inverse
    );
  }
}

.btn-outline-secondary {
  @include button-styles($font-color-inverse, $secondary-color, $font-color);

  &:hover,
  &:focus,
  &:active {
    @include button-styles($secondary-color, $secondary-color, $font-color);
  }
}

.link-primary {
  color: $primary-color;
}

.link-primary:hover {
  color: $primary-color-hover;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover {
  color: $font-color;
}
